import { default as activateovK0PkDW4MMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/activate.vue?macro=true";
import { default as indexJhCnjGXBxcMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/index.vue?macro=true";
import { default as restorecU8eGuLXVbMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/organisations/restore.vue?macro=true";
import { default as register_45complete2NcseTwRNxMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/register-complete.vue?macro=true";
import { default as registers2sVDTJ3jWMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/register.vue?macro=true";
import { default as _91token_93otauG8s1fsMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/boards/[token].vue?macro=true";
import { default as index3QcUqMgehuMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/buzzer/index.vue?macro=true";
import { default as _91id_93H9Qh0bU5IYMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/[id].vue?macro=true";
import { default as indexbXWBWgRfS1Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/index.vue?macro=true";
import { default as _91token_93da4HfoA7fQMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/token/[token].vue?macro=true";
import { default as tokenCc3ld5laAVMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/token.vue?macro=true";
import { default as demoXwOBEKDvDsMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/demo.vue?macro=true";
import { default as index8NQmHVAJnBMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/events/index.vue?macro=true";
import { default as impressumZnopGJmlxSMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/impressum.vue?macro=true";
import { default as indexvNJSHFlZ4ZMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/index.vue?macro=true";
import { default as _91id_93CmiRSZ3qS1Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/leaderboards/[id].vue?macro=true";
import { default as indexQ1dXiqNrysMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/leaderboards/index.vue?macro=true";
import { default as _91id_934covzlSx8DMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/deals/[id].vue?macro=true";
import { default as indexvoSmXcp0kTMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/deals/index.vue?macro=true";
import { default as HSLtoRGBrZt1CswaVNMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/HSLtoRGB.js?macro=true";
import { default as RGBtoHSLv4mwRuBPDOMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/RGBtoHSL.js?macro=true";
import { default as _91id_93Woh1KvEbLGMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/[id].vue?macro=true";
import { default as _91id_93rbutfSAPxPMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/devicegroup/[id].vue?macro=true";
import { default as indexzAd7Iq0KFeMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/index.vue?macro=true";
import { default as _91id_93iDcZRM6h12Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/eventTypes/[id].vue?macro=true";
import { default as indexrxRApNx7YuMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/eventTypes/index.vue?macro=true";
import { default as _91id_93yr67Ju5JN0Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/goals/[id].vue?macro=true";
import { default as indexnWjrQCplBYMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/goals/index.vue?macro=true";
import { default as indexlhQmwJICG2Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/settings/index.vue?macro=true";
import { default as indexTdT4wjniI6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/subscriptions/index.vue?macro=true";
import { default as _91id_932H1GroxcRRMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/users/[id].vue?macro=true";
import { default as indexV6q6soLCfaMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/users/index.vue?macro=true";
import { default as _91id_93kFWpZvYCUbMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/[id].vue?macro=true";
import { default as entriesd6v2UXX3okMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/index/entries.vue?macro=true";
import { default as playlistsgep6kltQrCMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/index/playlists.vue?macro=true";
import { default as indexKQjJtxKOYqMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/index.vue?macro=true";
import { default as indexkW8AQwqihoMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/devices/index.vue?macro=true";
import { default as _91id_93wWbTU8Oqb3Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/firmware/[id].vue?macro=true";
import { default as indexFxsWTKJExEMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/firmware/index.vue?macro=true";
import { default as _91id_93ISFFQut151Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/hardware/[id].vue?macro=true";
import { default as indexmtrvTN37MEMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/hardware/index.vue?macro=true";
import { default as indexq7l6JlZWbyMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/logs/index.vue?macro=true";
import { default as _91id_9369X5JuaG1HMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/notificationTemplates/[id].vue?macro=true";
import { default as indexoBWjGYKJr6Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/notificationTemplates/index.vue?macro=true";
import { default as _91id_93OjimYAJx8GMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/organisations/[id].vue?macro=true";
import { default as indexWdfcML4Yl9Meta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/organisations/index.vue?macro=true";
import { default as _91id_93mYPVi0VGebMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/plans/[id].vue?macro=true";
import { default as indexrFZ80caXrMMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/plans/index.vue?macro=true";
import { default as releaseloghstfQC8N5oMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/releaselog.vue?macro=true";
import { default as toolsViusR5hLIZMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/tools.vue?macro=true";
import { default as _91id_93V8h0Kped2JMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/users/[id].vue?macro=true";
import { default as indexjEFBm4x1asMeta } from "/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/users/index.vue?macro=true";
export default [
  {
    name: activateovK0PkDW4MMeta?.name ?? "auth-activate",
    path: activateovK0PkDW4MMeta?.path ?? "/auth/activate",
    meta: activateovK0PkDW4MMeta || {},
    alias: activateovK0PkDW4MMeta?.alias || [],
    redirect: activateovK0PkDW4MMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/activate.vue").then(m => m.default || m)
  },
  {
    name: indexJhCnjGXBxcMeta?.name ?? "auth",
    path: indexJhCnjGXBxcMeta?.path ?? "/auth",
    meta: indexJhCnjGXBxcMeta || {},
    alias: indexJhCnjGXBxcMeta?.alias || [],
    redirect: indexJhCnjGXBxcMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: restorecU8eGuLXVbMeta?.name ?? "auth-organisations-restore",
    path: restorecU8eGuLXVbMeta?.path ?? "/auth/organisations/restore",
    meta: restorecU8eGuLXVbMeta || {},
    alias: restorecU8eGuLXVbMeta?.alias || [],
    redirect: restorecU8eGuLXVbMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/organisations/restore.vue").then(m => m.default || m)
  },
  {
    name: register_45complete2NcseTwRNxMeta?.name ?? "auth-register-complete",
    path: register_45complete2NcseTwRNxMeta?.path ?? "/auth/register-complete",
    meta: register_45complete2NcseTwRNxMeta || {},
    alias: register_45complete2NcseTwRNxMeta?.alias || [],
    redirect: register_45complete2NcseTwRNxMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/register-complete.vue").then(m => m.default || m)
  },
  {
    name: registers2sVDTJ3jWMeta?.name ?? "auth-register",
    path: registers2sVDTJ3jWMeta?.path ?? "/auth/register",
    meta: registers2sVDTJ3jWMeta || {},
    alias: registers2sVDTJ3jWMeta?.alias || [],
    redirect: registers2sVDTJ3jWMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: _91token_93otauG8s1fsMeta?.name ?? "boards-token",
    path: _91token_93otauG8s1fsMeta?.path ?? "/boards/:token()",
    meta: _91token_93otauG8s1fsMeta || {},
    alias: _91token_93otauG8s1fsMeta?.alias || [],
    redirect: _91token_93otauG8s1fsMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/boards/[token].vue").then(m => m.default || m)
  },
  {
    name: index3QcUqMgehuMeta?.name ?? "buzzer",
    path: index3QcUqMgehuMeta?.path ?? "/buzzer",
    meta: index3QcUqMgehuMeta || {},
    alias: index3QcUqMgehuMeta?.alias || [],
    redirect: index3QcUqMgehuMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/buzzer/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93H9Qh0bU5IYMeta?.name ?? "dashboards-id",
    path: _91id_93H9Qh0bU5IYMeta?.path ?? "/dashboards/:id()",
    meta: _91id_93H9Qh0bU5IYMeta || {},
    alias: _91id_93H9Qh0bU5IYMeta?.alias || [],
    redirect: _91id_93H9Qh0bU5IYMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexbXWBWgRfS1Meta?.name ?? "dashboards",
    path: indexbXWBWgRfS1Meta?.path ?? "/dashboards",
    meta: indexbXWBWgRfS1Meta || {},
    alias: indexbXWBWgRfS1Meta?.alias || [],
    redirect: indexbXWBWgRfS1Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: tokenCc3ld5laAVMeta?.name ?? "dashboards-token",
    path: tokenCc3ld5laAVMeta?.path ?? "/dashboards/token",
    children: [
  {
    name: _91token_93da4HfoA7fQMeta?.name ?? "dashboards-token-token",
    path: _91token_93da4HfoA7fQMeta?.path ?? ":token()",
    meta: _91token_93da4HfoA7fQMeta || {},
    alias: _91token_93da4HfoA7fQMeta?.alias || [],
    redirect: _91token_93da4HfoA7fQMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/token/[token].vue").then(m => m.default || m)
  }
],
    meta: tokenCc3ld5laAVMeta || {},
    alias: tokenCc3ld5laAVMeta?.alias || [],
    redirect: tokenCc3ld5laAVMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/dashboards/token.vue").then(m => m.default || m)
  },
  {
    name: demoXwOBEKDvDsMeta?.name ?? "demo",
    path: demoXwOBEKDvDsMeta?.path ?? "/demo",
    meta: demoXwOBEKDvDsMeta || {},
    alias: demoXwOBEKDvDsMeta?.alias || [],
    redirect: demoXwOBEKDvDsMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/demo.vue").then(m => m.default || m)
  },
  {
    name: index8NQmHVAJnBMeta?.name ?? "events",
    path: index8NQmHVAJnBMeta?.path ?? "/events",
    meta: index8NQmHVAJnBMeta || {},
    alias: index8NQmHVAJnBMeta?.alias || [],
    redirect: index8NQmHVAJnBMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: impressumZnopGJmlxSMeta?.name ?? "impressum",
    path: impressumZnopGJmlxSMeta?.path ?? "/impressum",
    meta: impressumZnopGJmlxSMeta || {},
    alias: impressumZnopGJmlxSMeta?.alias || [],
    redirect: impressumZnopGJmlxSMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexvNJSHFlZ4ZMeta?.name ?? "index",
    path: indexvNJSHFlZ4ZMeta?.path ?? "/",
    meta: indexvNJSHFlZ4ZMeta || {},
    alias: indexvNJSHFlZ4ZMeta?.alias || [],
    redirect: indexvNJSHFlZ4ZMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CmiRSZ3qS1Meta?.name ?? "leaderboards-id",
    path: _91id_93CmiRSZ3qS1Meta?.path ?? "/leaderboards/:id()",
    meta: _91id_93CmiRSZ3qS1Meta || {},
    alias: _91id_93CmiRSZ3qS1Meta?.alias || [],
    redirect: _91id_93CmiRSZ3qS1Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/leaderboards/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQ1dXiqNrysMeta?.name ?? "leaderboards",
    path: indexQ1dXiqNrysMeta?.path ?? "/leaderboards",
    meta: indexQ1dXiqNrysMeta || {},
    alias: indexQ1dXiqNrysMeta?.alias || [],
    redirect: indexQ1dXiqNrysMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/leaderboards/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_934covzlSx8DMeta?.name ?? "organisation-deals-id",
    path: _91id_934covzlSx8DMeta?.path ?? "/organisation/deals/:id()",
    meta: _91id_934covzlSx8DMeta || {},
    alias: _91id_934covzlSx8DMeta?.alias || [],
    redirect: _91id_934covzlSx8DMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/deals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvoSmXcp0kTMeta?.name ?? "organisation-deals",
    path: indexvoSmXcp0kTMeta?.path ?? "/organisation/deals",
    meta: indexvoSmXcp0kTMeta || {},
    alias: indexvoSmXcp0kTMeta?.alias || [],
    redirect: indexvoSmXcp0kTMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/deals/index.vue").then(m => m.default || m)
  },
  {
    name: HSLtoRGBrZt1CswaVNMeta?.name ?? "organisation-devices-HSLtoRGB",
    path: HSLtoRGBrZt1CswaVNMeta?.path ?? "/organisation/devices/HSLtoRGB",
    meta: HSLtoRGBrZt1CswaVNMeta || {},
    alias: HSLtoRGBrZt1CswaVNMeta?.alias || [],
    redirect: HSLtoRGBrZt1CswaVNMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/HSLtoRGB.js").then(m => m.default || m)
  },
  {
    name: RGBtoHSLv4mwRuBPDOMeta?.name ?? "organisation-devices-RGBtoHSL",
    path: RGBtoHSLv4mwRuBPDOMeta?.path ?? "/organisation/devices/RGBtoHSL",
    meta: RGBtoHSLv4mwRuBPDOMeta || {},
    alias: RGBtoHSLv4mwRuBPDOMeta?.alias || [],
    redirect: RGBtoHSLv4mwRuBPDOMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/RGBtoHSL.js").then(m => m.default || m)
  },
  {
    name: _91id_93Woh1KvEbLGMeta?.name ?? "organisation-devices-id",
    path: _91id_93Woh1KvEbLGMeta?.path ?? "/organisation/devices/:id()",
    meta: _91id_93Woh1KvEbLGMeta || {},
    alias: _91id_93Woh1KvEbLGMeta?.alias || [],
    redirect: _91id_93Woh1KvEbLGMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93rbutfSAPxPMeta?.name ?? "organisation-devices-devicegroup-id",
    path: _91id_93rbutfSAPxPMeta?.path ?? "/organisation/devices/devicegroup/:id()",
    meta: _91id_93rbutfSAPxPMeta || {},
    alias: _91id_93rbutfSAPxPMeta?.alias || [],
    redirect: _91id_93rbutfSAPxPMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/devicegroup/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzAd7Iq0KFeMeta?.name ?? "organisation-devices",
    path: indexzAd7Iq0KFeMeta?.path ?? "/organisation/devices",
    meta: indexzAd7Iq0KFeMeta || {},
    alias: indexzAd7Iq0KFeMeta?.alias || [],
    redirect: indexzAd7Iq0KFeMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iDcZRM6h12Meta?.name ?? "organisation-eventTypes-id",
    path: _91id_93iDcZRM6h12Meta?.path ?? "/organisation/eventTypes/:id()",
    meta: _91id_93iDcZRM6h12Meta || {},
    alias: _91id_93iDcZRM6h12Meta?.alias || [],
    redirect: _91id_93iDcZRM6h12Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/eventTypes/[id].vue").then(m => m.default || m)
  },
  {
    name: indexrxRApNx7YuMeta?.name ?? "organisation-eventTypes",
    path: indexrxRApNx7YuMeta?.path ?? "/organisation/eventTypes",
    meta: indexrxRApNx7YuMeta || {},
    alias: indexrxRApNx7YuMeta?.alias || [],
    redirect: indexrxRApNx7YuMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/eventTypes/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yr67Ju5JN0Meta?.name ?? "organisation-goals-id",
    path: _91id_93yr67Ju5JN0Meta?.path ?? "/organisation/goals/:id()",
    meta: _91id_93yr67Ju5JN0Meta || {},
    alias: _91id_93yr67Ju5JN0Meta?.alias || [],
    redirect: _91id_93yr67Ju5JN0Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/goals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnWjrQCplBYMeta?.name ?? "organisation-goals",
    path: indexnWjrQCplBYMeta?.path ?? "/organisation/goals",
    meta: indexnWjrQCplBYMeta || {},
    alias: indexnWjrQCplBYMeta?.alias || [],
    redirect: indexnWjrQCplBYMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/goals/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhQmwJICG2Meta?.name ?? "organisation-settings",
    path: indexlhQmwJICG2Meta?.path ?? "/organisation/settings",
    meta: indexlhQmwJICG2Meta || {},
    alias: indexlhQmwJICG2Meta?.alias || [],
    redirect: indexlhQmwJICG2Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexTdT4wjniI6Meta?.name ?? "organisation-subscriptions",
    path: indexTdT4wjniI6Meta?.path ?? "/organisation/subscriptions",
    meta: indexTdT4wjniI6Meta || {},
    alias: indexTdT4wjniI6Meta?.alias || [],
    redirect: indexTdT4wjniI6Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_932H1GroxcRRMeta?.name ?? "organisation-users-id",
    path: _91id_932H1GroxcRRMeta?.path ?? "/organisation/users/:id()",
    meta: _91id_932H1GroxcRRMeta || {},
    alias: _91id_932H1GroxcRRMeta?.alias || [],
    redirect: _91id_932H1GroxcRRMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexV6q6soLCfaMeta?.name ?? "organisation-users",
    path: indexV6q6soLCfaMeta?.path ?? "/organisation/users",
    meta: indexV6q6soLCfaMeta || {},
    alias: indexV6q6soLCfaMeta?.alias || [],
    redirect: indexV6q6soLCfaMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/organisation/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kFWpZvYCUbMeta?.name ?? "sounds-id",
    path: _91id_93kFWpZvYCUbMeta?.path ?? "/sounds/:id()",
    meta: _91id_93kFWpZvYCUbMeta || {},
    alias: _91id_93kFWpZvYCUbMeta?.alias || [],
    redirect: _91id_93kFWpZvYCUbMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKQjJtxKOYqMeta?.name ?? "sounds",
    path: indexKQjJtxKOYqMeta?.path ?? "/sounds",
    children: [
  {
    name: entriesd6v2UXX3okMeta?.name ?? "sounds-index-entries",
    path: entriesd6v2UXX3okMeta?.path ?? "entries",
    meta: entriesd6v2UXX3okMeta || {},
    alias: entriesd6v2UXX3okMeta?.alias || [],
    redirect: entriesd6v2UXX3okMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/index/entries.vue").then(m => m.default || m)
  },
  {
    name: playlistsgep6kltQrCMeta?.name ?? "sounds-index-playlists",
    path: playlistsgep6kltQrCMeta?.path ?? "playlists",
    meta: playlistsgep6kltQrCMeta || {},
    alias: playlistsgep6kltQrCMeta?.alias || [],
    redirect: playlistsgep6kltQrCMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/index/playlists.vue").then(m => m.default || m)
  }
],
    meta: indexKQjJtxKOYqMeta || {},
    alias: indexKQjJtxKOYqMeta?.alias || [],
    redirect: indexKQjJtxKOYqMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/sounds/index.vue").then(m => m.default || m)
  },
  {
    name: indexkW8AQwqihoMeta?.name ?? "system-devices",
    path: indexkW8AQwqihoMeta?.path ?? "/system/devices",
    meta: indexkW8AQwqihoMeta || {},
    alias: indexkW8AQwqihoMeta?.alias || [],
    redirect: indexkW8AQwqihoMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/devices/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wWbTU8Oqb3Meta?.name ?? "system-firmware-id",
    path: _91id_93wWbTU8Oqb3Meta?.path ?? "/system/firmware/:id()",
    meta: _91id_93wWbTU8Oqb3Meta || {},
    alias: _91id_93wWbTU8Oqb3Meta?.alias || [],
    redirect: _91id_93wWbTU8Oqb3Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/firmware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFxsWTKJExEMeta?.name ?? "system-firmware",
    path: indexFxsWTKJExEMeta?.path ?? "/system/firmware",
    meta: indexFxsWTKJExEMeta || {},
    alias: indexFxsWTKJExEMeta?.alias || [],
    redirect: indexFxsWTKJExEMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/firmware/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ISFFQut151Meta?.name ?? "system-hardware-id",
    path: _91id_93ISFFQut151Meta?.path ?? "/system/hardware/:id()",
    meta: _91id_93ISFFQut151Meta || {},
    alias: _91id_93ISFFQut151Meta?.alias || [],
    redirect: _91id_93ISFFQut151Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/hardware/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmtrvTN37MEMeta?.name ?? "system-hardware",
    path: indexmtrvTN37MEMeta?.path ?? "/system/hardware",
    meta: indexmtrvTN37MEMeta || {},
    alias: indexmtrvTN37MEMeta?.alias || [],
    redirect: indexmtrvTN37MEMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexq7l6JlZWbyMeta?.name ?? "system-logs",
    path: indexq7l6JlZWbyMeta?.path ?? "/system/logs",
    meta: indexq7l6JlZWbyMeta || {},
    alias: indexq7l6JlZWbyMeta?.alias || [],
    redirect: indexq7l6JlZWbyMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/logs/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9369X5JuaG1HMeta?.name ?? "system-notificationTemplates-id",
    path: _91id_9369X5JuaG1HMeta?.path ?? "/system/notificationTemplates/:id()",
    meta: _91id_9369X5JuaG1HMeta || {},
    alias: _91id_9369X5JuaG1HMeta?.alias || [],
    redirect: _91id_9369X5JuaG1HMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/notificationTemplates/[id].vue").then(m => m.default || m)
  },
  {
    name: indexoBWjGYKJr6Meta?.name ?? "system-notificationTemplates",
    path: indexoBWjGYKJr6Meta?.path ?? "/system/notificationTemplates",
    meta: indexoBWjGYKJr6Meta || {},
    alias: indexoBWjGYKJr6Meta?.alias || [],
    redirect: indexoBWjGYKJr6Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/notificationTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OjimYAJx8GMeta?.name ?? "system-organisations-id",
    path: _91id_93OjimYAJx8GMeta?.path ?? "/system/organisations/:id()",
    meta: _91id_93OjimYAJx8GMeta || {},
    alias: _91id_93OjimYAJx8GMeta?.alias || [],
    redirect: _91id_93OjimYAJx8GMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/organisations/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWdfcML4Yl9Meta?.name ?? "system-organisations",
    path: indexWdfcML4Yl9Meta?.path ?? "/system/organisations",
    meta: indexWdfcML4Yl9Meta || {},
    alias: indexWdfcML4Yl9Meta?.alias || [],
    redirect: indexWdfcML4Yl9Meta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/organisations/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mYPVi0VGebMeta?.name ?? "system-plans-id",
    path: _91id_93mYPVi0VGebMeta?.path ?? "/system/plans/:id()",
    meta: _91id_93mYPVi0VGebMeta || {},
    alias: _91id_93mYPVi0VGebMeta?.alias || [],
    redirect: _91id_93mYPVi0VGebMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: indexrFZ80caXrMMeta?.name ?? "system-plans",
    path: indexrFZ80caXrMMeta?.path ?? "/system/plans",
    meta: indexrFZ80caXrMMeta || {},
    alias: indexrFZ80caXrMMeta?.alias || [],
    redirect: indexrFZ80caXrMMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/plans/index.vue").then(m => m.default || m)
  },
  {
    name: releaseloghstfQC8N5oMeta?.name ?? "system-releaselog",
    path: releaseloghstfQC8N5oMeta?.path ?? "/system/releaselog",
    meta: releaseloghstfQC8N5oMeta || {},
    alias: releaseloghstfQC8N5oMeta?.alias || [],
    redirect: releaseloghstfQC8N5oMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/releaselog.vue").then(m => m.default || m)
  },
  {
    name: toolsViusR5hLIZMeta?.name ?? "system-tools",
    path: toolsViusR5hLIZMeta?.path ?? "/system/tools",
    meta: toolsViusR5hLIZMeta || {},
    alias: toolsViusR5hLIZMeta?.alias || [],
    redirect: toolsViusR5hLIZMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/tools.vue").then(m => m.default || m)
  },
  {
    name: _91id_93V8h0Kped2JMeta?.name ?? "system-users-id",
    path: _91id_93V8h0Kped2JMeta?.path ?? "/system/users/:id()",
    meta: _91id_93V8h0Kped2JMeta || {},
    alias: _91id_93V8h0Kped2JMeta?.alias || [],
    redirect: _91id_93V8h0Kped2JMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/users/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjEFBm4x1asMeta?.name ?? "system-users",
    path: indexjEFBm4x1asMeta?.path ?? "/system/users",
    meta: indexjEFBm4x1asMeta || {},
    alias: indexjEFBm4x1asMeta?.alias || [],
    redirect: indexjEFBm4x1asMeta?.redirect || undefined,
    component: () => import("/home/forge/cloud.deal-buzzer.com/releases/20241221110621/client/pages/system/users/index.vue").then(m => m.default || m)
  }
]